import { Router } from '@reach/router';
import { OrderSummary } from 'components';
import PrivateRoute from 'components/PrivateRoute';
import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <Router basepath="/dashboard">
      <PrivateRoute path="/pregled-narudzbi" component={OrderSummary} />
      <PrivateRoute path="/racun" component={OrderSummary} />
    </Router>
  );
};

export default Dashboard;
