import { navigate } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../modules/redux-store';

interface Props {
  component: React.FC;
  location: { pathname: string };
  path?: string;
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  location,
  ...rest
}) => {
  const { isLoggedIn, authStateChanging } = useSelector(
    (state: ApplicationState) => state.auth,
  );

  if (!authStateChanging && !isLoggedIn && location.pathname !== `/login`) {
    // If we’re not logged in, redirect to the unauthorized page
    navigate(`/unauthorized`, { replace: true });
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
